/* eslint-disable complexity */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../../actions';
import medalSolid from '../../../assets/images/menu-icons/medal-solid.svg';
import resourcesIcon from '../../../assets/images/menu-icons/resources.svg';
import usersIcon from '../../../assets/images/menu-icons/users.svg';
import feedbackIcon from '../../../assets/images/menu-icons/feedback.svg';
import aboutUsIcon from '../../../assets/images/menu-icons/about-us.svg';
import faqIcon from '../../../assets/images/menu-icons/faq.svg';
import logOutIcon from '../../../assets/images/menu-icons/log-out-new.svg';
import pcsgradesIcon from '../../../assets/images/pcsgrades-logo.svg';
import listIcon from '../../../assets/images/list-icon.svg';
import statsIcon from '../../../assets/images/statistics-icon.svg';
import termsOfServiceIcon from '../../../assets/images/menu-icons/terms-of-service.svg';
import privacyPolicyIcon from '../../../assets/images/menu-icons/privacy-policy.svg';
import supportIcon from '../../../assets/images/menu-icons/support.svg';

const AdminNav = (props) => {
  const isPathActive = (path) => {
    const isActive = props.location.pathname.startsWith(path);
    if (isActive && path !== '/resources' && props.resourcesSubPagesOpen) {
      // props.resourcesSubPagesOpen doesn't exist in props. You might want to pass it as a prop.
      // this.setState({ resourcesSubPagesOpen: false });
    }
    return isActive;
  };
  return (
    <ul className="nav">
      <>
        <li
          className={
            isPathActive('/admins/users') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/admins/users">
            <img className="menu-icon" src={usersIcon} alt="Users" />
            <span className="menu-title">Users</span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/admins/referrals') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/admins/referrals">
            <img className="menu-icon" src={pcsgradesIcon} alt="Users" />
            <span className="menu-title">Referrals</span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/admins/user-events') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/admins/user-events">
            <img className="menu-icon" src={listIcon} alt="User Events" />
            <span className="menu-title">User Events</span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/admins/statistics') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/admins/statistics">
            <img className="menu-icon" src={statsIcon} alt="User Events" />
            <span className="menu-title">Statistics</span>
          </Link>
        </li>
      </>

      <li
        className={isPathActive('/benefits') ? 'nav-item active' : 'nav-item'}
      >
        <Link className="nav-link" to="/benefits">
          <img className="menu-icon" src={medalSolid} alt="Benefits" />
          <span className="menu-title">Benefits</span>
        </Link>
      </li>
      <li
        className={isPathActive('/resources') ? 'nav-item active' : 'nav-item'}
      >
        <Link className="nav-link" to="/resources">
          <img className="menu-icon" src={resourcesIcon} alt="Resources" />
          <span className="menu-title">Organizations</span>
        </Link>
      </li>
      <div className="divider-item"></div>

      <li
        className={isPathActive('/about-us') ? 'nav-item active' : 'nav-item'}
      >
        <Link className="nav-link" to="/about-us">
          <img className="menu-icon" src={aboutUsIcon} alt="About Us" />
          <span className="menu-title">About Us</span>
        </Link>
      </li>
      <li className={isPathActive('/faq') ? 'nav-item active' : 'nav-item'}>
        <Link className="nav-link" to="/faq">
          <img className="menu-icon" src={faqIcon} alt="FAQ" />
          <span className="menu-title">FAQs</span>
        </Link>
      </li>

      <li
        className={isPathActive('/feedback') ? 'nav-item active' : 'nav-item'}
      >
        <Link className="nav-link" to="/feedback">
          <img className="menu-icon" src={feedbackIcon} alt="Feedback" />
          <span className="menu-title">Feedback</span>
        </Link>
      </li>

      <div className="divider-item"></div>
      <li
        className={
          isPathActive('/terms-of-use') ? 'nav-item active' : 'nav-item'
        }
      >
        <Link className="nav-link" to="/terms-of-use">
          <img
            className="menu-icon"
            src={termsOfServiceIcon}
            alt="Terms of Use"
          />
          <span className="menu-title">Terms of Use</span>
        </Link>
      </li>
      <li
        className={
          isPathActive('/privacy-policy') ? 'nav-item active' : 'nav-item'
        }
      >
        <Link className="nav-link" to="/privacy-policy">
          <img
            className="menu-icon"
            src={privacyPolicyIcon}
            alt="Privacy Policy"
          />
          <span className="menu-title">Privacy Policy</span>
        </Link>
      </li>

      <li className={isPathActive('/support') ? 'nav-item active' : 'nav-item'}>
        <Link className="nav-link" to="/support">
          <img className="menu-icon" src={supportIcon} alt="Support" />
          <span className="menu-title">Support</span>
        </Link>
      </li>

      <li
        className="nav-item"
        onClick={() => {
          props.history.push('/login');
          props.logout();
        }}
      >
        <div className="nav-link">
          <img className="menu-icon" src={logOutIcon} alt="Log out" />
          <span className="menu-title">Log Out</span>
        </div>
      </li>
    </ul>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.user,
  isAdmin: state.session.isAdmin,
  isEditor: state.session.isEditor,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminNav));
