import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class LoggedOutNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountModal: false,
    };
  }

  render() {
    const goBack = () => {
      if (this.props.history.length > 1) {
        this.props.history.goBack();
      } else {
        window.close();
      }
    };
    return (
      <nav className="navbar left-0 col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <button
            className="logout-navbar-toggler align-self-center"
            type="button"
            onClick={() => goBack()}
          >
            <i className="mdi mdi-chevron-left mr-2"></i>
            <span>Back</span>
          </button>
        </div>
      </nav>
    );
  }
}

export default withRouter(LoggedOutNavbar);
