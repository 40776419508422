/* eslint-disable max-params */
import interceptor from './interceptor';
import { apiUrl } from '../environment/index';

export const leadPartnersGetAllReferrals = (
  page = 1,
  startDate,
  endDate,
  keyword = '',
  limit = 25
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(`${apiUrl}/lead_partners/referral`, {
    params: {
      page,
      startDate,
      endDate,
      keyword,
      limit,
    },
  });
};

export const leadPartnerExportReferrals = (
  startDate = '',
  endDate = '',
  filterValues = [],
  searchKeywords
) => {
  return interceptor.get(`${apiUrl}/lead_partners/export/referrals`, {
    params: {
      startDate,
      endDate,
      filterValues: filterValues.join(','),
      searchKeywords,
    },
    responseType: 'blob',
    timeout: 30000,
  });
};
