import TagManager from 'react-gtm-module';

/**
 * Initialize GTM.
 * @param {Object} options Options for starting GTM.
 * @param {string} options.gtmId ID for GTM account.
 * @param {string} options.auth Auth ID for GTM preview.
 * @param {string} options.preview Preview ID for GTM preview.
 */
export function startGTM(options) {
  const params = {};
  params.gtmId = options.gtmId;

  if (!!options.auth && !!options.preview) {
    params.auth = options.auth;
    params.preview = options.preview;
  }

  TagManager.initialize(params);
}
