import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  saveUser,
  setIsAdmin,
  logout,
  setSessionStartedAt,
  setIsEditor,
  setIsLeadPartner,
} from '../actions';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import { getUser } from '../api-routes';
import LoggedOutNavbar from './shared/LoggedOutNavbar';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentLoaded: false,
    };
  }

  // Need to split this up and figure out what the heck this responsible for.
  // Seems like they're trying to make sure the user is updated when the app loads
  // this is causing several issues and I think we need to reconsider.
  async componentDidMount() {
    const user = await localStorage.getItem('user');
    if (user) {
      const userData = JSON.parse(user);
      this.props.saveUser(userData);
      this.props.setIsAdmin(userData.userType === 'Admin');
      this.props.setIsEditor(userData.userType === 'Editor');
      this.props.setIsLeadPartner(userData.userType === 'LeadPartner');

      const { data: updatedUser } = await getUser();

      if (updatedUser) {
        this.props.saveUser(updatedUser);
        this.props.setIsAdmin(updatedUser.userType === 'Admin');
        this.props.setIsEditor(updatedUser.userType === 'Editor');
        this.props.setIsLeadPartner(updatedUser.userType === 'LeadPartner');
        localStorage.setItem('user', JSON.stringify(updatedUser));
      } else {
        this.props.logout();
      }
    }
    this.addRemoveClass();
    this.setState({ componentLoaded: true });
    this.addRemoveClass();
  }

  render() {
    const hasUser = !!this.props.user;
    const navbarComponent = !this.hideNavebar() ? (
      hasUser ? (
        <Navbar />
      ) : (
        <LoggedOutNavbar />
      )
    ) : (
      ''
    );
    const sidebarComponent = !this.hideNavebar() && hasUser ? <Sidebar /> : '';
    this.addRemoveClass();

    const mainPanelStyles = clsx('main-panel', {
      'logged-in': hasUser,
    });

    return (
      <>
        {this.state.componentLoaded && (
          <div className="container-scroller">
            {navbarComponent}
            <div className="container-fluid page-body-wrapper">
              {sidebarComponent}
              <div className={mainPanelStyles}>
                <div className="content-wrapper outer-content-wrapper">
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  hideNavebar() {
    const fullPageLayoutRoutes = [
      '/login',
      '/change-password',
      '/register',
      '/email-verification',
      '/resert-password',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
    ];
    const index = fullPageLayoutRoutes.findIndex(
      (path) => path === this.props.location.pathname
    );
    return index !== -1;
  }

  addRemoveClass() {
    if (
      this.hideNavebar() ||
      (!this.props.user && this.props.location.pathname === '/faq')
    ) {
      if (document.querySelector('.page-body-wrapper')) {
        document
          .querySelector('.page-body-wrapper')
          .classList.add('full-page-wrapper');
      }
    } else {
      if (document.querySelector('.page-body-wrapper')) {
        document
          .querySelector('.page-body-wrapper')
          .classList.remove('full-page-wrapper');
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
  isAdmin: state.session.isAdmin,
  sessionStartedAt: state.session.sessionStartedAt,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => {
    dispatch(saveUser(user));
  },
  setIsAdmin: (isAdmin) => {
    dispatch(setIsAdmin(isAdmin));
  },
  setIsEditor: (isEditor) => {
    dispatch(setIsEditor(isEditor));
  },
  setIsLeadPartner: (isLeadPartner) => {
    dispatch(setIsLeadPartner(isLeadPartner));
  },
  logout: () => {
    dispatch(logout());
  },
  setSessionStartedAt: (sessionStartedAt) => {
    dispatch(setSessionStartedAt(sessionStartedAt));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
