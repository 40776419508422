import interceptor from './interceptor';
import { apiUrl } from '../environment/index';

export const editorsGetAllBenefits = (page, keyword = '') => {
  return interceptor.get(
    `${apiUrl}/editors/benefits?page=${page}&keyword=${keyword}`
  );
};

export const editorsGetStateAgency = () => {
  return interceptor.get(`${apiUrl}/editors/resources`);
};

export const editorsGetBenefitCategories = () => {
  return interceptor.get(`${apiUrl}/editors/benefits/category`);
};

export const editorsCreateBenefit = (benefit) => {
  return interceptor.post(`${apiUrl}/editors/benefits`, benefit);
};

export const editorsDeleteBenefit = (benefitID) => {
  return interceptor.delete(`${apiUrl}/editors/benefits/${benefitID}`);
};

export const editorsUpdateBenefit = (benefit) => {
  return interceptor.put(`${apiUrl}/editors/benefits/${benefit._id}`, benefit);
};
