import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';

import whiteLogo from '../../../assets/images/logo-white.svg';
import logoOnly from '../../../assets/images/logo-only.svg';
import medalSolid from '../../../assets/images/menu-icons/medal-solid.svg';
import resourcesIcon from '../../../assets/images/menu-icons/resources.svg';
import termsOfServiceIcon from '../../../assets/images/menu-icons/terms-of-service.svg';
import privacyPolicyIcon from '../../../assets/images/menu-icons/privacy-policy.svg';
import supportIcon from '../../../assets/images/menu-icons/support.svg';
import logOutIcon from '../../../assets/images/menu-icons/log-out-new.svg';

const EditorSidebar = ({ location, logout }) => {
  const isPathActive = (path) => location.pathname.startsWith(path);
  const history = useHistory();

  const logoutEditor = () => {
    logout();
    history.push('/login');
  };

  return (
    <nav className="sidebar sidebar-offcanvas editor-sidebar" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="index.html">
          <img src={whiteLogo} alt="logo" />
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
          <img src={logoOnly} alt="logo" />
        </a>
      </div>
      <ul className="nav">
        <li
          className={
            isPathActive('/state-agency') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/state-agency">
            <img className="menu-icon" src={resourcesIcon} alt="Resources" />
            <span className="menu-title">Profile</span>
          </Link>
        </li>

        <li
          className={isPathActive('/benefits') ? 'nav-item active' : 'nav-item'}
        >
          <Link className="nav-link" to="/benefits">
            <img className="menu-icon" src={medalSolid} alt="Benefits" />
            <span className="menu-title">Benefits</span>
          </Link>
        </li>

        <div className="divider-item"></div>
        <li
          className={
            isPathActive('/terms-of-use') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/terms-of-use">
            <img
              className="menu-icon"
              src={termsOfServiceIcon}
              alt="Terms of Use"
            />
            <span className="menu-title">Terms of Use</span>
          </Link>
        </li>
        <li
          className={
            isPathActive('/privacy-policy') ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link" to="/privacy-policy">
            <img
              className="menu-icon"
              src={privacyPolicyIcon}
              alt="Privacy Policy"
            />
            <span className="menu-title">Privacy Policy</span>
          </Link>
        </li>
        <li
          className={isPathActive('/support') ? 'nav-item active' : 'nav-item'}
        >
          <Link className="nav-link" to="/support">
            <img className="menu-icon" src={supportIcon} alt="Support" />
            <span className="menu-title">Support</span>
          </Link>
        </li>
        <li className="nav-item" onClick={() => logoutEditor()}>
          <div className="nav-link">
            <img className="menu-icon" src={logOutIcon} alt="Log out" />
            <span className="menu-title">Log Out</span>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.user,
  isEditor: state.session.isEditor,
});

const mapDispatchToProps = (_dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditorSidebar));
