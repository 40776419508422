import {
  SAVE_USER,
  SET_IS_ADMIN,
  SET_IS_EDITOR,
  LOGOUT,
  SET_NAV_HEADER,
  SET_SAVE_BENEFIT,
  SET_SESSION_STARTED_AT,
  SET_IS_LEAD_PARTNER,
} from '../actions/types';

const initState = {
  user: null,
  isAdmin: false,
  isEditor: false,
  isLeadPartner: false,
  navHeader: null,
  saveBenefit: null,
  sessionStartedAt: null,
};

const sessionReducer = (state, action) => {
  if (!state) {
    return initState;
  }
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        user: { ...action.user },
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.val,
      };
    case SET_IS_EDITOR:
      return {
        ...state,
        isEditor: action.val,
      };
    case SET_IS_LEAD_PARTNER:
      return {
        ...state,
        isLeadPartner: action.val,
      };
    case SET_NAV_HEADER:
      return {
        ...state,
        navHeader: action.navHeader,
      };
    case SET_SAVE_BENEFIT:
      return {
        ...state,
        saveBenefit: action.saveBenefit,
      };
    case LOGOUT:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('sessionStartedAt');
      // window.location.reload()
      return initState;
    case SET_SESSION_STARTED_AT:
      return {
        ...state,
        sessionStartedAt: action.val,
      };
    default:
      return state;
  }
};

export default sessionReducer;
