/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { Collapse, Button, Modal } from 'react-bootstrap';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

import { logout } from '../../actions';
import { addUserEvent, getAllResources } from '../../api-routes';
import { imageUrl } from '../../environment';

import whiteLogo from '../../assets/images/logo-white.svg';
import logoOnly from '../../assets/images/logo-only.svg';
import medalSolid from '../../assets/images/menu-icons/medal-solid.svg';
import resourcesIcon from '../../assets/images/menu-icons/resources.svg';
import usersIcon from '../../assets/images/menu-icons/users.svg';
import feedbackIcon from '../../assets/images/menu-icons/feedback.svg';
import aboutUsIcon from '../../assets/images/menu-icons/about-us.svg';
import connectIcon from '../../assets/images/menu-icons/connect-icon.svg';
import faqIcon from '../../assets/images/menu-icons/faq.svg';
import termsOfServiceIcon from '../../assets/images/menu-icons/terms-of-service.svg';
import privacyPolicyIcon from '../../assets/images/menu-icons/privacy-policy.svg';
import logOutIcon from '../../assets/images/menu-icons/log-out-new.svg';
import shareWithFriendsIcon from '../../assets/images/menu-icons/share-with-friends.svg';
import copyIcon from '../../assets/images/copy-icon.svg';
import EditorSidebar from './components/EditorSidebar';
import unsavedFlag from '../../assets/images/unsaved-flag.png';
import AdminNav from './components/AdminsNav';
import LeadPartnerSidebar from './components/LeadPartnerSidebar';

class Sidebar extends Component {
  state = {
    open: false,
    resources: [],
    selectedResource: '',
    showCopyMessage: false,
    showShareModal: false,
    shareText:
      'Check out this great app that connects service members, veterans, and families to 1000’s of earned benefits at Federal, State, non-profit, and local levels. It cuts through all the noise and matches benefits directly to your unique service background.',
    shareUrl: `https://www.myvetbenefits.com/`,
    resourcesSubPagesOpen: false,
  };

  handleClose = () => this.setState({ showShareModal: false });
  handleShow = async () => {
    this.setState({ showShareModal: true });
  };

  shareApp = async (shareType) => {
    await addUserEvent({
      type: 'shared_app',
      properties: {
        shareType: shareType,
      },
    });
  };

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    this.getResourcesList();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    // Object.keys(this.state).forEach((i) => {
    //   this.setState({ [i]: false });
    // });

    // const dropdownPaths = [
    //   { path: "/moves", state: "resourcesSubPagesOpen" },
    //   { path: "/users", state: "usersPagesMenuOpen" },
    //   { path: "/companies", state: "companiesPagesMenuOpen" },
    //   { path: "/background-check", state: "bgCheckPagesMenuOpen" },
    // ];

    // dropdownPaths.forEach((obj) => {
    //   if (this.isPathActive(obj.path)) {
    //     this.setState({ [obj.state]: true });
    //   }
    // });
  }

  async getResourcesList() {
    try {
      this.setState({ loadingResources: true });
      const { data: resources } = await getAllResources();
      this.setState({ resources, loadingResources: false });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error getting resources', error);
    }
  }

  isPathActive(path) {
    const isActive = this.props.location.pathname.startsWith(path);
    if (isActive && path !== '/resources' && this.state.resourcesSubPagesOpen) {
      // this.setState({ resourcesSubPagesOpen: false });
    }
    return isActive;
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ resourcesSubPagesOpen: false });
    } else {
      this.setState({ resourcesSubPagesOpen: true });
    }
  }

  closeSubMenu() {
    this.setState({ resourcesSubPagesOpen: false });
    this.setState({ selectedResource: null });
  }

  selectResource(resource = null) {
    if (!resource) {
      resource =
        this.state.resources.length > 0 ? this.state.resources[0] : null;
    }
    this.setState({ selectedResource: resource._id });
    if (resource) {
      this.props.history.push(
        `/resources/${resource.title.toLowerCase().replace(/ /g, '-')}`,
        {
          category: resource,
        }
      );
    }
  }

  resetSelectedResource() {
    this.setState({ selectedResource: null });
  }

  copyTextToClipboard = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(
        `${this.state.shareText}\n${this.state.shareUrl}`
      );
    } else {
      const el = document.createElement('textarea');
      el.value = `${this.state.shareText}\n${this.state.shareUrl}`;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
    this.setState({ showCopyMessage: true });
  };

  // @todo Need to simplify this render method, it exceeds max allowed complexity.
  // eslint-disable-next-line complexity
  render() {
    const { resources, loadingResources, showCopyMessage } = this.state;

    if (this.props.isEditor) {
      return (
        <EditorSidebar location={this.location} logout={this.props.logout} />
      );
    }

    if (this.props.isLeadPartner) {
      return (
        <LeadPartnerSidebar
          location={this.location}
          logout={this.props.logout}
        />
      );
    }

    let sidebarClassname = 'sidebar sidebar-offcanvas';
    if (this.props.isAdmin) {
      sidebarClassname = 'sidebar admins-sidebar sidebar-offcanvas';
    }

    return (
      <>
        <nav className={sidebarClassname} id="sidebar">
          <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
            <a className="sidebar-brand brand-logo" href="index.html">
              <img src={whiteLogo} alt="logo" />
            </a>
            <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
              <img src={logoOnly} alt="logo" />
            </a>
          </div>
          {this.props.isAdmin ? (
            <AdminNav
              history={this.props.history}
              logout={this.props.logout}
              isPathActive={this.isPathActive}
            />
          ) : (
            <ul className="nav">
              <li
                className={
                  this.isPathActive('/benefits')
                    ? 'nav-item active'
                    : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link className="nav-link" to="/benefits">
                  <img className="menu-icon" src={medalSolid} alt="Benefits" />
                  <span className="menu-title">Benefits</span>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive('/resources')
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <div
                  className={
                    this.state.resourcesSubPagesOpen
                      ? 'nav-link sub-menu-link menu-expanded'
                      : 'nav-link sub-menu-link'
                  }
                  onClick={() => this.toggleMenuState('resourcesSubPagesOpen')}
                  data-toggle="collapse"
                >
                  <img
                    className="menu-icon"
                    src={resourcesIcon}
                    alt="Resources"
                  />
                  <span className="menu-title">Organizations</span>
                  {this.state.resourcesSubPagesOpen ? (
                    <i className="menu-arrow fa fa-caret-down"></i>
                  ) : (
                    <i className="menu-arrow fa fa-caret-right"></i>
                  )}
                </div>
              </li>
              <Collapse in={this.state.resourcesSubPagesOpen}>
                <ul className="nav flex-column sub-menu">
                  {!loadingResources ? (
                    <>
                      {this.props.user.savedResourceItems?.length > 0 && (
                        <li className="nav-item">
                          <Link
                            className={
                              this.isPathActive('/saved-resources')
                                ? 'nav-link active'
                                : 'nav-link'
                            }
                            to="/saved-resources"
                            onClick={() => this.resetSelectedResource()}
                          >
                            <img
                              className="menu-icon"
                              src={unsavedFlag}
                              alt="bookmark flag"
                            />
                            <span className="menu-title">
                              Saved Organizations
                            </span>
                          </Link>
                        </li>
                      )}

                      {resources.map((resource) => {
                        return (
                          <li className="nav-item" key={resource._id}>
                            <div
                              className={
                                this.state.selectedResource === resource._id
                                  ? 'nav-link active'
                                  : 'nav-link'
                              }
                              onClick={() => this.selectResource(resource)}
                            >
                              {resource.icon && (
                                <img
                                  className="menu-icon"
                                  src={`${imageUrl}/${resource.icon}`}
                                  alt="Resources"
                                />
                              )}
                              <span className="menu-title">
                                {resource.title}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </Collapse>

              <li
                className={
                  this.isPathActive('/connect-with')
                    ? 'nav-item active'
                    : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link
                  className="nav-link"
                  to={{
                    pathname: '/connect-with',
                    state: { userData: this.props.user },
                  }}
                >
                  <img className="menu-icon" src={connectIcon} alt="Users" />
                  <span className="menu-title">Get Connected</span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive('/profile') ? 'nav-item active' : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link
                  className="nav-link"
                  to={{
                    pathname: '/profile',
                    state: { userData: this.props.user, pageType: 'profile' },
                  }}
                >
                  <img className="menu-icon" src={usersIcon} alt="Users" />
                  <span className="menu-title">Profile</span>
                </Link>
              </li>

              <div className="divider-item"></div>

              <li
                className={
                  this.isPathActive('/feedback')
                    ? 'nav-item active'
                    : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link className="nav-link" to="/feedback">
                  <img
                    className="menu-icon"
                    src={feedbackIcon}
                    alt="Feedback"
                  />
                  <span className="menu-title">Feedback</span>
                </Link>
              </li>

              <li className="nav-item" onClick={() => this.handleShow()}>
                <a className="nav-link">
                  <img
                    className="menu-icon"
                    src={shareWithFriendsIcon}
                    alt="Share With Friends"
                  />
                  <span className="menu-title">Share With Friends</span>
                </a>
              </li>

              <li
                className={
                  this.isPathActive('/faq') ? 'nav-item active' : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link className="nav-link" to="/faq">
                  <img className="menu-icon" src={faqIcon} alt="FAQ" />
                  <span className="menu-title">FAQs</span>
                </Link>
              </li>

              <div className="divider-item"></div>

              <li
                className={
                  this.isPathActive('/about-us')
                    ? 'nav-item active'
                    : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link className="nav-link" to="/about-us">
                  <img className="menu-icon" src={aboutUsIcon} alt="About Us" />
                  <span className="menu-title">About Us</span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive('/terms-of-use')
                    ? 'nav-item active'
                    : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link className="nav-link" to="/terms-of-use">
                  <img
                    className="menu-icon"
                    src={termsOfServiceIcon}
                    alt="Terms of Use"
                  />
                  <span className="menu-title">Terms of Use</span>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive('/privacy-policy')
                    ? 'nav-item active'
                    : 'nav-item'
                }
                onClick={() => this.closeSubMenu()}
              >
                <Link className="nav-link" to="/privacy-policy">
                  <img
                    className="menu-icon"
                    src={privacyPolicyIcon}
                    alt="Privacy Policy"
                  />
                  <span className="menu-title">Privacy Policy</span>
                </Link>
              </li>

              <li
                className="nav-item"
                onClick={() => {
                  this.props.history.push('/login');
                  this.props.logout();
                }}
              >
                <div className="nav-link">
                  <img className="menu-icon" src={logOutIcon} alt="Log out" />
                  <span className="menu-title">Log Out</span>
                </div>
              </li>
            </ul>
          )}
        </nav>
        <Snackbar
          open={showCopyMessage}
          autoHideDuration={3000}
          onClose={() => this.setState({ showCopyMessage: false })}
          message="Text copied to clipboard"
        />
        <Modal show={this.state.showShareModal} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Share With Friends</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.shareText}
            <div className="social-share-button">
              <FacebookShareButton
                url={this.state.shareUrl}
                quote={this.state.shareText}
                onClick={() => this.shareApp('Web Facebook')}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={this.state.shareUrl}
                title={this.state.shareText}
                onClick={() => this.shareApp('Web Twitter')}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton
                url={this.state.shareUrl}
                title={this.state.shareText}
                className="Demo__some-network__share-button"
                onClick={() => this.shareApp('Web Telegram')}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <WhatsappShareButton
                url={this.state.shareUrl}
                title={this.state.shareText}
                separator=":: "
                className="Demo__some-network__share-button"
                onClick={() => this.shareApp('Web Whatsapp')}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={this.state.shareUrl}
                className="Demo__some-network__share-button"
                onClick={() => this.shareApp('Web LinkedIn')}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <div onClick={() => this.shareApp('Web Email')}>
                <EmailShareButton
                  url={this.state.shareUrl}
                  subject={this.state.shareText}
                  body="body"
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
              <div
                className="clipboard-icon"
                onClick={() => {
                  this.copyTextToClipboard();
                  this.shareApp('Web Copied');
                }}
              >
                <img src={copyIcon} alt="copy" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
  isAdmin: state.session.isAdmin,
  isEditor: state.session.isEditor,
  isLeadPartner: state.session.isLeadPartner,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
