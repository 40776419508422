import {
  SAVE_USER,
  SET_IS_ADMIN,
  SET_IS_EDITOR,
  SET_IS_LEAD_PARTNER,
  LOGOUT,
  SET_NAV_HEADER,
  SET_SAVE_BENEFIT,
  SET_SESSION_STARTED_AT,
} from './types';

export const saveUser = (user) => ({
  type: SAVE_USER,
  user,
});

export const setIsAdmin = (val) => ({
  type: SET_IS_ADMIN,
  val,
});

export const setIsEditor = (val) => ({
  type: SET_IS_EDITOR,
  val,
});

export const setIsLeadPartner = (val) => ({
  type: SET_IS_LEAD_PARTNER,
  val,
});

export const setNavHeader = (navHeader) => ({
  type: SET_NAV_HEADER,
  navHeader,
});

export const setSaveBenefit = (saveBenefit) => ({
  type: SET_SAVE_BENEFIT,
  saveBenefit,
});

export const logout = (user) => ({
  type: LOGOUT,
  user,
});

export const setSessionStartedAt = (user) => ({
  type: SET_SESSION_STARTED_AT,
  user,
});
