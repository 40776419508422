import interceptor from './interceptor';
import { apiUrl } from '../environment/index';

export const login = (data) => {
  return interceptor.post(`${apiUrl}/auth/login`, data);
};

export const register = (data) => {
  return interceptor.post(`${apiUrl}/auth/register`, data);
};

export const fbLogin = (data) => {
  return interceptor.post(`${apiUrl}/auth/login-facebook`, data);
};

export const googleLogin = (data) => {
  return interceptor.post(`${apiUrl}/auth/login-google`, data);
};

export const registerSite = () => {
  return interceptor.get(`${apiUrl}/registration`);
};

export const recoverPassword = (data) => {
  return interceptor.post(`${apiUrl}/auth/forgot-password`, data);
};

export const otpVerification = (data) => {
  return interceptor.post(`${apiUrl}/auth/otp-verification`, data);
};

export const ResertPasswordApi = (userID, data) => {
  return interceptor.post(`${apiUrl}/auth/reset-password/${userID}`, data);
};

export const changePassword = (data) => {
  return interceptor.post(`${apiUrl}/change-password`, data);
};

// @todo Need to refactor to accept a single object argument instead of multiple.
export const getAllBenefits = (
  id = '',
  page,
  benefitType = 'all',
  keyword = '',
  categoryIds = [],
  govFilter = '',
  dependentFilter = [],
  stateFilter = []
  // eslint-disable-next-line max-params
) => {
  const queryParams = new URLSearchParams({
    id,
    page,
    benefitType,
    keyword,
    govFilter,
    categoryIds: categoryIds.join(','),
    dependentFilter: dependentFilter.join(','),
    stateFilter: stateFilter.join(','),
  });
  return interceptor.get(
    `${apiUrl}/benefits/by-type/${benefitType}?${queryParams}`
  );
};

export const getBenefit = (id) => {
  return interceptor.get(`${apiUrl}/benefits/${id}`);
};

export const getAllResources = () => {
  return interceptor.get(`${apiUrl}/resources`);
};

export const getResourceDetails = (resourceId) => {
  return interceptor.get(`${apiUrl}/resources/${resourceId}`);
};

export const getResourceItem = (id) => {
  return interceptor.get(`${apiUrl}/resources/items/${id}`);
};

export const getSavedResourceItem = () => {
  return interceptor.get(`${apiUrl}/resources/saved-items`);
};

export const getUser = () => {
  return interceptor.get(`${apiUrl}/users/profile`);
};

export const updateUser = (user) => {
  return interceptor.put(`${apiUrl}/users/profile`, user);
};

export const deleteUser = () => {
  return interceptor.delete(`${apiUrl}/users/profile`);
};

export const updateUserPassword = (user) => {
  return interceptor.put(`${apiUrl}/users/profile/update-password`, user);
};

export const getPage = (page) => {
  return interceptor.get(`${apiUrl}/pages/${page.slug}`);
};

export const getAllFaq = () => {
  return interceptor.get(`${apiUrl}/faq`);
};

// @todo Need to refactor to accept a single object argument instead of multiple.
export const getFeedbacks = (
  page = 1,
  type = 'All',
  startDate = '',
  endDate = ''
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(
    `${apiUrl}/feedback?page=${page}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const addFeedback = (feedback) => {
  return interceptor.post(`${apiUrl}/feedback`, feedback);
};

export const addExternalFeedback = (feedback) => {
  return interceptor.post(`${apiUrl}/feedback/contact-request`, feedback);
};

export const addBenefitFeedback = (feedback) => {
  return interceptor.post(`${apiUrl}/feedback/benefit-feedback`, feedback);
};

// export const getFeedbacks = (page = 1, type = 'all') => {
//   return interceptor.get(`${apiUrl}/feedback?page=${page}&type=${type}`);
// };

export const addFeedbackReply = (feedbackId, reply) => {
  return interceptor.post(`${apiUrl}/feedback/${feedbackId}/reply`, reply);
};

export const callLogout = () => {
  return interceptor.get(`${apiUrl}/logout`);
};

export const callLogout2 = () => {
  return interceptor.get(`${apiUrl}/logout2`);
};

export const addReferral = (referral) => {
  return interceptor.post(`${apiUrl}/referral`, referral);
};

export const updateReferral = (id, referral) => {
  return interceptor.put(`${apiUrl}/referral/${id}`, referral);
};

export const updateReferralHealthcare = (id, referral) => {
  return interceptor.put(`${apiUrl}/referral/healthcare/${id}`, referral);
};

export const getReferralByUser = () => {
  return interceptor.get(`${apiUrl}/referral/by-user`);
};

export const addUserEvent = (event) => {
  return interceptor.post(`${apiUrl}/user-events`, event);
};
