import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserHistory } from 'history';

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { store } from '../src/config';
import { setupAnalytics } from './utils/analytics';

export const history = createBrowserHistory();

setupAnalytics();

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <BrowserRouter history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
