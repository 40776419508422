export class TokenService {
  constructor(store) {
    this.store = store || localStorage;
  }

  getLocalAccessToken() {
    return this.store.getItem('token');
  }

  getLocalRefreshToken() {
    return this.store.getItem('refreshToken');
  }

  getUser() {
    return JSON.parse(this.store.getItem('user'));
  }

  removeLocalAccessToken() {
    this.store.removeItem('token');
  }

  removeLocalRefreshToken() {
    this.store.removeItem('refreshToken');
  }

  removeUser() {
    this.store.removeItem('user');
  }

  setLocalAccessToken(token) {
    this.store.setItem('token', token);
  }

  setLocalRefreshToken(token) {
    this.store.setItem('refreshToken', token);
  }

  setUser(user) {
    this.store.setItem('user', JSON.stringify(user));
  }
}

export default new TokenService();
