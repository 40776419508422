import { startGTM } from './gtm';

export function setupAnalytics() {
  const env = process.env.NODE_ENV;
  const enableDevAnalytics =
    process.env.REACT_APP_ENABLE_DEV_ANALYTICS || 'false';

  if (env === 'production' || enableDevAnalytics === 'true') {
    const gtmId = process.env.REACT_APP_GTM_ID;
    const gtmAuth = process.env.REACT_APP_GTM_AUTH_ID;
    const gtmPreview = process.env.REACT_APP_GTM_PREVIEW_ID;

    startGTM({ gtmId, auth: gtmAuth, preview: gtmPreview });
  }
}
